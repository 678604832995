var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"main container-fluid"},[(_vm.work_section?.header)?_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-column mb-3"},[_c('h5',{staticClass:"text-second"},[_vm._v("HOW IT WORKS")]),_c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"f-secondary text-gradient-2 w-70"},[_vm._v(" "+_vm._s(_vm.work_section?.header?.title)+" ")])]),_c('p',[_vm._v(_vm._s(_vm.work_section?.header?.subTitle))])]):_vm._e(),_vm._l((_vm.work_section?.sections),function(section,index){return _c('div',{key:index,staticClass:"row align-items-center my-5"},[_c('div',{class:[
          'col-md-6 text-start',
          index % 2 === 0 ? 'order-md-1' : 'order-md-2 text-md-end',
          'order-2',
        ]},[_c('h5',{staticClass:"text-second text-uppercase mb-3"},[_vm._v(_vm._s(section.title))]),_c('h2',{staticClass:"f-secondary text-gradient-2 font-40 mb-3"},[_vm._v(" "+_vm._s(section.heading)+" ")]),_c('p',{staticClass:"w-80"},[_vm._v(_vm._s(section.description))]),(section?.buttonText)?_c('div',{staticClass:"d-flex justify-content-md-start justify-content-center p-2"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push(section.btnLink)}}},[_vm._v(_vm._s(section.buttonText))])]):_vm._e()]),_c('div',{class:[
          'col-md-6 text-center',
          index % 2 === 0
            ? 'order-md-2 text-md-end'
            : 'order-md-1 text-md-start',
          'order-1',
          'mb-5 mb-md-0',
        ]},[_c('div',{staticClass:"patterned-image"},[_c('img',{staticClass:"img-fluid border-radius-40",attrs:{"src":section.image,"alt":section.altText}})])])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }