<template>
  <div class="f-primary">
    <section class="main gradient-light" style="margin-top: -15px">
      <div class="d-flex flex-column flex-md-row mb-5">
        <div
          class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-start p-3"
        >
          <h1 class="f-secondary t-secondary text-gradient p-2">
            {{ section?.title }}
          </h1>
          <div class="f-primary p-2 line-height-30 mb-3 bold">
            {{ section?.desc }}
          </div>
          <div class="d-flex justify-content-start p-2" v-if="section?.btnText">
            <button
              class="btn btn-primary"
              @click="$router.push('/web/register')"
            >
              {{ section?.btnText }}
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 p-0">
          <img :src="section?.image" class="img-fluid" />
        </div>
      </div>
    </section>
    <BenefitsSection v-if="section_name != 'jobs'" />
    <WorksSection :sectionType="section_name" />
    <div v-if="section_name == 'jobs'" class="text-center">
      <h3 class="f-secondary t-secondary text-gradient p-2">
        Find the Right Job For You
      </h3>
      <div
        class="container border-radius-40 row col-12 p-4"
        style="background-color: rgba(237, 246, 247, 1)"
      >
        <div class="col-3">
          <img
            width="250"
            height="300"
            src="../../../public/img/stm/jobs.jpg"
            class="img-fluid border-radius-40"
          />
        </div>
        <div class="text-left col-5">
          <h1 class="f-secondary font-30 text-dark-primary">Explore Jobs</h1>
          <p>
            No matter what stage you’re at in your career, we’ll find the right
            role for you.
          </p>
        </div>
        <div class="col-4">
          <button
            class="btn btn-primary"
            @click="$router.push(`/tfw/job-board/${CONST.CUSTOMERS.super_customer}`)"
          >
            Explore Jobs
          </button>
        </div>
      </div>
    </div>
    <FutureSection />
    <NewFooter />
    <ScrollToTop />
  </div>
</template>

<script>
import BenefitsSection from "./BenefitsSection";
import WorksSection from "./WorksSection";
import FutureSection from "./FutureSection";
import NewFooter from "./NewFooter.vue";
import { mapActions } from "vuex";
import { CONSTANT as CONST }  from '../../helpers/helper.js'
import ScrollToTop from "../../components/reusable/ScrollToTop.vue";

export default {
  components: {
    BenefitsSection,
    WorksSection,
    FutureSection,
    NewFooter,
    ScrollToTop,
  },
  data() {
    return {
      pathways: {
        professionals: {
          title: "Take your career to the next level with TalentFind",
          desc: "Time for a change? Whether you’re looking to make a local move or head overseas for new opportunities, we’re here to connect you with roles that are right for you. Plus, you can explore accredited training, upskilling, and E-learning opportunities, all in one place.",
          btnText: "Register Now",
          image: "/img/stm/professionals.png",
        },
        students: {
          title: "Supporting the healthcare talent of the future",
          desc: "Taking the first steps in your healthcare career is an exciting prospect, but we know there’s a lot to navigate. That’s why we’re here to set you up for success with one platform that includes everything from sponsorship opportunities and personalised job recommendations to guidance on international career development and online training – so you can begin your career with confidence..",
          btnText: "Register Now",
          image: "/img/stm/student-banner.png",
        },
        jobs: {
          title: "Finding a role with TalentFind",
          desc: "Our platform has been designed to make finding your perfect role simpler. Let our in-built AI match you to roles with 100% accuracy – so you can spend less time searching, and more time building your career. Find out more about the employers we’ve partnered with, and explore roles by specialism, right here.",
          image: "/img/stm/job-banner.png",
        },
      },
      section: null,
      section_name: "",
      CONST,
    };
  },
  methods: {
    ...mapActions(["scrollTop"]),
    loadData() {
      this.section_name = this.$route.params.section_name;
      this.section = this.pathways[this.section_name];
    },
  },
  watch: {
    "$route.params.section_name": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.scrollTop();
        this.loadData();
      }
    },
  },
  mounted() {
    this.scrollTop();
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
