<template>
  <div>
    <section class="main my-md">
      <h5 class="text-second text-center">BENEFITS</h5>
      <div class="text-center">
        <h2 class="f-secondary text-gradient-2">Why choose TalentFind?</h2>
      </div>

      <div
        class="col-md-12 col-sm-11 mx-auto"
        v-if="career_pathway == 'professionals'"
      >
        <div class="row gx-5 mt-5">
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Join a global talent pool
            </p>
            Make yourself and your skills visible to top healthcare recruiters
            around the world with a public profile, or browse the wide range of
            features privately until you’re ready to look for a new role.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Unique opportunities
            </p>
            Whether you’re a Physician, Nurse, Midwife, Allied Health
            Professional or in a Social Care role, a world of healthcare
            opportunities await with TalentFind.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Personalised job recommendations
            </p>
            Complete your profile and our AI-matching technology will present
            you with the roles that are right for you.
          </div>
        </div>
        <div class="row gx-5 mt-md-5">
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Secure document storage
            </p>
            Safely store your documents and data for the whole of your career
            and make job applications simpler.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Connect with your community
            </p>
            Connect with a global community of other professionals sharing your
            healthcare journey.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Further training and support
            </p>
            Access language training, courses and VISA support to enable you to
            secure your dream role.
          </div>
        </div>
      </div>

      <div class="col-md-12 col-sm-11 mx-auto" v-else>
        <div class="row gx-5 mt-5">
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Join a global talent pool
            </p>
            Make yourself and your skills visible to top healthcare recruiters
            around the world.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Unique opportunities
            </p>
            From sponsorship to clinical placements and exchange programs, a
            world of healthcare opportunities await with TalentFind.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Personalised job recommendations
            </p>
            Complete your profile and our AI-matching technology will present
            you with the roles that are right for you.
          </div>
        </div>
        <div class="row gx-5 mt-md-5">
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Secure document storage
            </p>
            Safely store your documents and data for the whole of your career
            and make job applications simpler.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Connect with your community
            </p>
            Connect with a global community of other professionals sharing your
            healthcare journey.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Further training and support
            </p>
            Access language training, courses and VISA support to enable you to
            secure your dream role.
          </div>
        </div>
      </div>
    </section>
    <ScrollToTop />
  </div>
</template>

<script>
import ScrollToTop from "../../components/reusable/ScrollToTop.vue";

export default {
  components: {
    ScrollToTop,
  },
  data() {
    return {
      career_pathway: this.$route.params.section_name,
    };
  },
  watch: {
    '$route.params.section_name'(newVal) {
      this.career_pathway = newVal;
    }
  },
  mounted() {
    this.career_pathway = this.$route.params.section_name;
  }
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
