<template>
  <div>
    <section class="main container-fluid">
      <div
        class="d-flex justify-content-center align-items-center flex-column mb-3"
        v-if="work_section?.header"
      >
        <h5 class="text-second">HOW IT WORKS</h5>
        <div class="text-center">
          <h2 class="f-secondary text-gradient-2 w-70">
            {{ work_section?.header?.title }}
          </h2>
        </div>
        <p>{{ work_section?.header?.subTitle }}</p>
      </div>
      <!-- Starts -->
      <div
        v-for="(section, index) in work_section?.sections"
        :key="index"
        class="row align-items-center my-5"
      >
        <!-- Left Content OR Right Content -->
        <div
          :class="[
            'col-md-6 text-start',
            index % 2 === 0 ? 'order-md-1' : 'order-md-2 text-md-end',
            'order-2',
          ]"
        >
          <h5 class="text-second text-uppercase mb-3">{{ section.title }}</h5>
          <h2 class="f-secondary text-gradient-2 font-40 mb-3">
            {{ section.heading }}
          </h2>
          <p class="w-80">{{ section.description }}</p>
          <div
            class="d-flex justify-content-md-start justify-content-center p-2"
            v-if="section?.buttonText"
          >
            <button class="btn btn-primary" @click="$router.push(section.btnLink)">{{ section.buttonText }}</button>
          </div>
        </div>

        <!-- Right Image OR Left Image -->
        <div
          :class="[
            'col-md-6 text-center',
            index % 2 === 0
              ? 'order-md-2 text-md-end'
              : 'order-md-1 text-md-start',
            'order-1',
            'mb-5 mb-md-0',
          ]"
        >
          <div class="patterned-image">
            <img
            class="img-fluid border-radius-40"
            :src="section.image"
            :alt="section.altText"
          />
          </div>
        </div>
      </div>
      <!-- Ends -->
    </section>
  </div>
</template>

<script>
import WorkSectionJSON from "./work_section.json";
import { mapActions } from "vuex";

export default {
  props: {
    sectionType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      work_section: {},
    };
  },
  watch: {
    sectionType(newVal) {
      console.log("Updated sectionType: ", newVal);
      this.updateWorkSection();
    },
  },
  methods: {
    ...mapActions(["scrollTop"]),
    updateWorkSection() {
      if (this.sectionType) {
        this.work_section = WorkSectionJSON[this.sectionType] || {};
      }
    },
  },
  mounted() {
    this.scrollTop();
    this.updateWorkSection();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
